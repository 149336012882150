import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './_components/navbar/navbar.component';
import { InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { msalConfig, loginRequest } from './_services/auth-config';
import { Mk3MaterialModule } from './_shared/material-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CaseComponent } from './_components/case/case.component';
import { CaseDetailComponent } from './_components/case-detail/case-detail.component';
import { AlertComponent } from './_components/alert/alert.component';
import { HomeComponent } from './_components/home';
import { ReportsComponent } from './_components/reports/reports.component';
import { E2eEolComponent } from './_components/reports/e2e-eol/e2e-eol.component';
import { CatClaimsComponent } from './_components/reports/cat-claims/cat-claims.component';
import { ClaimReceivedSummaryComponent } from './_components/reports/claim-received-summary/claim-received-summary.component';
import { AllOpenClaimsComponent } from './_components/reports/all-open-claims/all-open-claims.component';
import { AddNoteComponent } from './_components/case-detail/add-note/add-note.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoFocusDirective } from './_shared/auto-focus.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InputRefNoDialog } from './_components/home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedFilesComponent } from './_components/shared-files/shared-files.component';
import { StatisticsComponent } from './_components/statistics/statistics.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
    loginFailedRoute: '/login-failed'
  };
}
@NgModule({ declarations: [
        AppComponent,
        NavbarComponent,
        CaseComponent,
        CaseDetailComponent,
        AlertComponent,
        HomeComponent,
        ReportsComponent,
        E2eEolComponent,
        CatClaimsComponent,
        ClaimReceivedSummaryComponent,
        AllOpenClaimsComponent,
        AddNoteComponent,
        AutoFocusDirective,
        InputRefNoDialog,
        SharedFilesComponent,
        StatisticsComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MsalModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        Mk3MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        MatTableExporterModule,
        MatDatepickerModule,
        FlexLayoutModule,
        ModalModule.forRoot()], providers: [
        DatePipe,
        DecimalPipe,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        MatDatepickerModule,
        MatNativeDateModule,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
