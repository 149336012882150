import { Injectable } from "@angular/core"; 
import { MsalService } from '@azure/msal-angular';
import { Subject } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class GlobalValues{

    isDescriptionDDLCloseOnSelect:boolean = true;

    userInGroupList = this.msalService.instance.getAllAccounts()[0].idTokenClaims;
    userEmailId = this.msalService.instance.getActiveAccount()!.username! ;
    userName = this.msalService.instance.getActiveAccount()!.name!;
    accessToken : any;
    

constructor(private msalService: MsalService 
  ){
  }
}