import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Note } from '@app/_models/note';
import { AccountService, AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';
import { first } from 'rxjs/operators';

export interface DialogData {
  noteDescription: string;
}

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {
  noteDescription!: string;
  refNo!: number;
  form!: UntypedFormGroup;
  loading = false;
  submitted = false;
  newNote!: Note;
  result!: string;

  constructor(public dialogRef: MatDialogRef<AddNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { note: string, refNo: number, caseNo: string, lastNames: string }, private formBuilder: UntypedFormBuilder, private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private restService: RestService,
    public functionsService: FunctionsService,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    //if (this.accountService.isLoggedIn()) {
      this.form = this.formBuilder.group({
        noteDescription: [null, Validators.required]
      })
    //}
  }

  async onSubmit() {
    //if (this.accountService.isLoggedIn()) {
      if (this.form.controls['noteDescription'].value.trim() != '') {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();
        // stop here if form is invalid
        if (this.form.invalid) {
          return;
        }

        const userFullName = this.accountService.getName();// this.accountService.userValue.firstName + ' ' + this.accountService.userValue.lastName;

        this.newNote = {
          refNo: this.data.refNo,//59200,//
          //date: new Date().toISOString().slice(0, 10),
          date: this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()!,
          addedBy: userFullName,
          initiatedBy: "Insurer",
          noteTypeId: 3,
          subTypeId: 8,
          reasonId: 29,
          notes: this.form.controls['noteDescription'].value,
          private: 0,
          timeStamp: null,
          followUpDate: this.functionsService.getNextBusinessDay().toISOString().slice(0, 10),
          followUpUser: null,
          followUpComplete: null,
          clientId: 1
        };

        this.loading = true;
        this.restService.addNewNote(this.newNote)
          .pipe(first())
          .subscribe({
            next: () => {
              this.dialogRef.close();
              this.alertService.success('New note has been added successfully', { keepAfterRouteChange: true });
              this.router.navigate(['../case-details/' + this.newNote.refNo], { relativeTo: this.route }).then(() => {
                window.location.reload();
              })
            },
            error: (error: string) => {
              this.loading = false;
              this.alertService.error(error);
            }
          });
        this.dialogRef.close();
      }
    //}
  }
  close() { }
}
