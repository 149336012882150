<nav class="navbar navbar-expand-lg navbar-dark" style='background-color: #222420;'>
  <img id="logo" height="28" class="mr-2" alt="Logo" src="_assets/mk3-logo.png">

  <a class="navbar-brand" [routerLink]="['/home']">Proplab Portal</a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <button type="button" class="btn btn-outline-success dropdown-toggle" data-toggle="dropdown">
          <span class="fa fa-user"></span>
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#"> <i class="fa fa-sign-in"></i> Sign In </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
