<div class="app-container" *ngIf="accountService.isUserLoggedIn() === true">
  <!-- <div class="app-container"> -->
    <div class="loading-container" *ngIf="isLoading">
      <div class="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="portal-header">
      <h5 class="header-text">Claims Summary</h5>
    </div>

    <div style="width:82%;">
      <mat-grid-list cols="1" rowHeight="11rem" style="margin-left: 155px; margin-top: 55px;">
        <mat-grid-tile>
          <mat-list>
            <div mat-subheader>Live Current Data</div>
            <mat-list-item class="list-key-column"> Total Open Claims </mat-list-item>
            <mat-list-item class="list-key-column"> Claims yet to be Inspected </mat-list-item>
            <mat-list-item class="list-key-column"> Claims Re-Opened </mat-list-item>
          </mat-list>

          <mat-list>
            <div mat-subheader></div>
            <mat-list-item class="list-value-column"> {{summary.totalOpen}} </mat-list-item>
            <mat-list-item class="list-value-column"> {{summary.totalYetToBeInspected}} </mat-list-item>
            <mat-list-item class="list-value-column"> {{summary.totalReOpened}} </mat-list-item>
          </mat-list>

          <mat-list>
            <mat-list-item class="list-key-column"> Claims 30 days or Less </mat-list-item>
            <mat-list-item class="list-key-column"> Claims 31-60 days old </mat-list-item>
            <mat-list-item class="list-key-column"> Claims 61-90 days old </mat-list-item>
            <mat-list-item class="list-key-column"> Claims over 90 days old </mat-list-item>
          </mat-list>

          <mat-list>
            <mat-list-item class="list-value-column"> {{summary.total30DaysOrLess}} </mat-list-item>
            <mat-list-item class="list-value-column"> {{summary.total31To60DaysOld}} </mat-list-item>
            <mat-list-item class="list-value-column"> {{summary.total61To90DaysOld}} </mat-list-item>
            <mat-list-item class="list-value-column"> {{summary.totalOver90DaysOld}} </mat-list-item>
          </mat-list>

        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <div style="width:90%;">

      <div style="width:35%; margin-left: 155px; float:left;">
        <div mat-subheader class="subheaderfont" style="margin-left: 155px;">Claims Summary for Period
          {{startDate| date: 'dd/MM/yyyy'}} -
          {{endDate| date: 'dd/MM/yyyy'}}</div>

        <mat-grid-list cols="1" rowHeight="34rem">

          <mat-grid-tile>
            <mat-list class="summary-key-list">
              <div mat-subheader>Claims Summary </div>
              <mat-list-item class="list-key-column"> Claims Received </mat-list-item>
              <mat-list-item class="list-key-column"> Contact within 24 Hours </mat-list-item>
              <mat-list-item class="list-key-column"> Contact within 48 Hours </mat-list-item>
              <mat-list-item class="list-key-column"> Claims Inspected </mat-list-item>
              <div mat-subheader>Finalised Claims</div>

              <mat-list-item class="list-key-column"> Total Claims Finalised </mat-list-item>
              <mat-list-item class="list-key-column"> Accepted </mat-list-item>
              <mat-list-item class="list-key-column"> Partially Accepted </mat-list-item>
              <mat-list-item class="list-key-column"> Denial Recommended </mat-list-item>
              <mat-list-item class="list-key-column"> Cancelled </mat-list-item>
              <mat-list-item class="list-key-column"> Other </mat-list-item>
              <mat-list-item class="list-key-column"> Average Claim Cycle (Days) </mat-list-item>
            </mat-list>

            <mat-list class="summary-value-list">
              <div mat-subheader></div>
              <mat-list-item class="list-value-column"> {{summary.receivedCount}} </mat-list-item>
              <mat-list-item class="list-value-column"> {{summary.contact24}}
                ({{summary.contact24/summary.receivedCount*100 | number : '1.0-0'}}%) </mat-list-item>
              <mat-list-item class="list-value-column"> {{summary.contact48}}
                ({{summary.contact48/summary.receivedCount*100 | number : '1.0-0'}}%)</mat-list-item>
              <mat-list-item class="list-value-column"> {{summary.inspectedCount}} </mat-list-item>
              <div mat-subheader></div>
              <mat-list-item class="list-value-column"> {{summary.finalisedCountAll}} </mat-list-item>
              <mat-list-item class="list-value-column"> {{summary.acceptedFinalised}} </mat-list-item>
              <mat-list-item class="list-value-column"> {{summary.partialFinalised}} </mat-list-item>
              <mat-list-item class="list-value-column"> {{summary.denialRecommendedFinalised}} </mat-list-item>
              <mat-list-item class="list-value-column"> {{summary.cancelledCount}} </mat-list-item>
              <mat-list-item class="list-value-column">
                {{summary.finalisedCountAll - summary.acceptedFinalised - summary.partialFinalised - summary.denialRecommendedFinalised}}
              </mat-list-item>
              <mat-list-item class="list-value-column"> {{summary.cycleTime}} (Excluding CAT claims)</mat-list-item>
            </mat-list>
          </mat-grid-tile>
        </mat-grid-list>

      </div>

      <div style="height:50rem; width:30%; margin-left: 120px; float:left;">
        <div mat-subheader class="subheaderfont">Claims by Peril Type for Period {{startDate| date: 'dd/MM/yyyy'}} -
          {{endDate| date: 'dd/MM/yyyy'}}</div>
        <table mat-table [dataSource]="dataSourcePerils" style="border:0px;text-align: left;">
          <th mat-header>tt</th>
          <ng-container matColumnDef="peril">
            <td mat-cell *matCellDef="let perils" style="border:0px;text-align: left; 
                    height: 100%; padding-top:12px; overflow: visible;width: 100%;">{{perils.peril}}</td>
          </ng-container>
          <ng-container matColumnDef="count">
            <td mat-cell *matCellDef="let perils" style="border:0px;text-align: right;">{{perils.count}}</td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: displayedPerilsColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999">
              No perils found
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div style="height:50rem; width:20%; margin-left: 15px; float:left;">
      <div mat-subheader class="subheaderfont" style="margin-left: 5px;">Select the Period (Start & End Dates)</div>
      <div class="search-box-container-c">
        <mat-form-field appearance="fill">
          <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate" required [formGroup]="dateRange">
            <input matStartDate matInput placeholder="Start Date" formControlName="startDate"
              (dateChange)="startDateChangeEvent($event)" (focus)="picker.open()">
            <input matEndDate matInput placeholder="End Date" formControlName="endDate"
              (dateChange)="endDateChangeEvent($event)"(focus)="picker.open()">
          </mat-date-range-input>

        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker class="fixed-open" #picker></mat-date-range-picker><!--  opened="true" touchUi -->
          <mat-error>Invalid or out of range date. Please enter valid date range within past 2 years</mat-error>
          <mat-error *ngIf="dateRange.controls.startDate.hasError('dateTooOld')">
            Start date cannt be more than 2 years old
          </mat-error>
          <mat-error *ngIf="dateRange.controls.endDate.hasError('dateTooOld')">
            End date cannt be more than 2 years old
          </mat-error>
        </mat-form-field>
      </div>
  </div>
