import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { Statistics } from '@app/_models/statistics';
import { VClaimReport } from '@app/_models/vClaimReport';
import { YearlyStatistics } from '@app/_models/yearlyStatistics';
import { AccountService, AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  selectedTabIndex: number = 0;
  today:Date = new Date();
  top3Loss:string='';

  displayedColumnsYearly: string[] = [
    'sla',
    'mK3Target',
    'monthlyAverage',
  ];
  dataSourceYearlyStatistics!:MatTableDataSource<YearlyStatistics>;
  yearlyStatistics: any[] = [];

  displayedColumnsReport: string[] = [
    'claimNumber',
    'otherReferenceNumber',
    'propertyAddress',
    'state',
    'postCode',
    'claimType',
    'quantumOfClaim',
    'dateOfAppointment',
    'dateOfInitialCustomerContact',
    'dateOfSiteVisit',
    'dateOfFirstReport',
    'dateClosed',
    'statusWithPanels',
    'anticipatedCompletionDate'
  ];

  dataSourceClaimsReport = new MatTableDataSource<VClaimReport>();
  
  isLoading = false;
  activeLinkIndex = -1;

  sortColumn =
    localStorage.getItem('reportSortColumn') != null
      ? localStorage.getItem('reportSortColumn')
      : 'otherReferenceNumber';
  sortDirection =
    localStorage.getItem('reportSortDirection') != null
      ? localStorage.getItem('reportSortDirection')
      : 'desc';
  
  pageSizeControl = new FormControl(
    localStorage.getItem('pageSize')
      ? Number(localStorage.getItem('pageSize'))
      : 10
  );

  @ViewChild(MatSort) matSort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;

  ngOnInit() {
    this.alertService.clear();
    this.today = new Date();
    this.loadTop3Loss(5, new Date());
    this.getYearlyStatistics();
  }

  onTabClick(event: any) {
    this.selectedTabIndex = event.index;
    this.alertService.clear();
    if(this.selectedTabIndex == 0)
      this.getYearlyStatistics();
    else {
      if (this.dataSourceClaimsReport.data.length > 0)
        this.refreshData();
      else
        this.getClaimReport();
    }
  }

  constructor(
    private restService: RestService,
    private route: ActivatedRoute,
    private router: Router,
    public accountService: AccountService,
    public functionsService: FunctionsService,
    private alertService: AlertService,
  ) { }

  setColumns(statistics:YearlyStatistics[]): void {
    if (statistics) {
      if (!this.displayedColumnsYearly.includes('january') && this.yearlyStatistics.some(x => x['january'] !== null))
        this.displayedColumnsYearly.push('january');
      if (!this.displayedColumnsYearly.includes('february') && this.yearlyStatistics.some(x => x['february'] !== null))
        this.displayedColumnsYearly.push('february');
      if (!this.displayedColumnsYearly.includes('march') && this.yearlyStatistics.some(x => x['march'] !== null))
        this.displayedColumnsYearly.push('march');
      if (!this.displayedColumnsYearly.includes('april') && this.yearlyStatistics.some(x => x['april'] !== null))
        this.displayedColumnsYearly.push('april');
      if (!this.displayedColumnsYearly.includes('may') && this.yearlyStatistics.some(x => x['may'] !== null))
        this.displayedColumnsYearly.push('may');
      if (!this.displayedColumnsYearly.includes('june') && this.yearlyStatistics.some(x => x['june'] !== null))
        this.displayedColumnsYearly.push('june');
      if (!this.displayedColumnsYearly.includes('july') && this.yearlyStatistics.some(x => x['july'] !== null))
        this.displayedColumnsYearly.push('july');
      if (!this.displayedColumnsYearly.includes('august') && this.yearlyStatistics.some(x => x['august'] !== null))
        this.displayedColumnsYearly.push('august');
      if (!this.displayedColumnsYearly.includes('september') && this.yearlyStatistics.some(x => x['september'] !== null))
        this.displayedColumnsYearly.push('september');
      if (!this.displayedColumnsYearly.includes('october') && this.yearlyStatistics.some(x => x['october'] !== null))
        this.displayedColumnsYearly.push('october');
      if (!this.displayedColumnsYearly.includes('november') && this.yearlyStatistics.some(x => x['november'] !== null))
        this.displayedColumnsYearly.push('november');
      if (!this.displayedColumnsYearly.includes('december') && this.yearlyStatistics.some(x => x['december'] !== null))
        this.displayedColumnsYearly.push('december');
    }
  }

  async getClaimReport(clientId: number = 5) {
    this.alertService.clear();
    this.isLoading = true;
    try {
      const response = await this.restService.getVClaimReport(clientId);
      if (response &&  Array.isArray(response)) {
        this.dataSourceClaimsReport= new MatTableDataSource(this.functionsService.excludeTestClaims(response,'otherReferenceNumber'));//(response.filter(x=> !TestRefNos.includes(x['OtherReferenceNumber'])));
        
        if (
          this.pageSizeControl.value != null &&
          this.pageSizeControl.value > 100 &&
          this.dataSourceClaimsReport.data.length !=
            Number(localStorage.getItem('pageSize'))
        ) {
          localStorage.setItem(
            'pageSize',
            this.dataSourceClaimsReport.data.length.toString()
          );
        }
        if (!this.matSort ){
          this.matSort = new MatSort();
        }
        this.dataSourceClaimsReport.sort = this.matSort;
        this.dataSourceClaimsReport.paginator = this.paginator;
        this.matSort.sort({ id: this.sortColumn, start: this.sortDirection, disableClear: true } as MatSortable);
        this.isLoading = false;
      } else {
        console.error('Invalid response structure:', response); // Debug log
      }
    } catch (error) {
    console.error('Error fetching paged statistics:', error); // Debug log
    }
        this.isLoading = false;
  }

  refreshData() {
    this.isLoading = true;
    localStorage.setItem('pageSize', localStorage.getItem('pageSize')!.toString());
    this.paginator._changePageSize(Number(localStorage.getItem('pageSize')));
    this.dataSourceClaimsReport.sort = this.matSort;
    this.dataSourceClaimsReport.paginator = this.paginator;
    this.matSort.sort({ id: this.sortColumn, start: this.sortDirection, disableClear: true } as MatSortable);  
    this.isLoading = false;
  }

  loadTop3Loss(clientId: number, date: Date) {
    this.restService.getTop3Loss(clientId, date).subscribe({
      next: (data: string) => {
        this.top3Loss = data;
      },
      error: (error) => {
        console.error('Error fetching top 3 loss:', error);
        this.top3Loss = 'Error fetching data';
      }
    });
  }

  async getYearlyStatistics(): Promise<void> {
    this.alertService.clear();
    this.isLoading = true;

    try {
      const response = await this.restService.getYearlyStatistics(5, new Date());

      if (response && response.records && Array.isArray(response.records)) {
        this.yearlyStatistics = response.records;
        this.dataSourceYearlyStatistics = new MatTableDataSource(response.records);
      } else {
        console.error('Invalid response structure:', response); 
      }
    } catch (error) {
      console.error('Error fetching paged statistics:', error); 
    } 
    finally {
      this.isLoading = false;
      this.setColumns(this.yearlyStatistics);
    }
  }

  async onPageSizeChanged(){
    localStorage.setItem('pageSize', this.paginator.pageSize.toString());//this.paginator.pageSize.toString());
  }

  sortData(sort: Sort) {
    localStorage.setItem('reportSortColumn', sort.active);
    localStorage.setItem('reportSortDirection', sort.direction);
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction;
  }

  }