import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RestService } from '@app/_services/rest.service';
import { first } from 'rxjs/operators';
import { AlertService } from '@app/_services';
import { File } from '../../_models/file';
import { BlobServiceClient } from '@azure/storage-blob';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-shared-files',
  templateUrl: './shared-files.component.html',
  styleUrls: ['./shared-files.component.scss']
})

export class SharedFilesComponent implements OnInit {
  isLoading = false;
  jobFolder: string = '';
  files: File[] = [{ fileName: '' }];
  displayedColumns: string[] = ['fileName', 'view', 'download'];
  queryParam1: string = '';
  queryParam2: string = '';

  constructor(private http: HttpClient, private restService: RestService, private alertService: AlertService,private route:ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.queryParam1 = queryParams['param1'];
      this.queryParam2 = queryParams['param2'];
    });
    this.jobFolder = this.queryParam1 + ' '+this.queryParam2;
    this.getClaimFiles(this.jobFolder);
  }

  getClaimFiles(jobFolder: string) {
    this.isLoading = true;
    this.restService.getClaimFiles(jobFolder)
      .pipe(first())
      .subscribe(files => {
        this.files = files;
        this.isLoading = false;
      },
        error => {
          this.alertService.error(error);
          this.isLoading = false;
        });
  }

  downloadFile(fileName: string, view?: boolean) {
    this.isLoading = true;
    if (fileName) {
      this.restService.downloadFile(this.jobFolder, fileName).subscribe(response => {

        let blob: Blob = response.body as Blob;
        var fileURL = URL.createObjectURL(blob);

        // if you want to open PDF in new tab
        //window.open(fileURL);

        var aLink = document.createElement("a");
        document.body.appendChild(aLink);
        aLink.target = '_blank';
        aLink.href = fileURL;

        if (view == false) {
          //let fName = response.headers.get('content-disposition')?.split(';')[1].split('=')[1].replace(/"/g, '');
          if (fileName)
            aLink.download = fileName;
        }
        aLink.click();

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          document.body.removeChild(aLink);
          window.URL.revokeObjectURL(fileURL);
        }, 100);
        this.isLoading = false;
      },
        (error) => {
          console.log('getPDF error: ', error);
          this.alertService.error(error);
          this.isLoading = false;
        });
    }
  }

  async getFilesList() {
    const account = "mk3files";
    const sasToken = "your_sas_token"; // Replace with your SAS token

    const blobServiceClient = new BlobServiceClient(
      `https://${account}.blob.core.windows.net?${sasToken}`
    );

    const containerName = "your-container-name"; // Replace with your container name
    const containerClient = blobServiceClient.getContainerClient(containerName);

    let blobs = containerClient.listBlobsFlat();
    let i = 1;
    for await (const blob of blobs) {
      console.log(`Blob ${i}: ${blob.name}`);
      i++;
    }
  }

  async getFilesWithSaS() {
    const account = "mk3files";
    const sasToken = "your_sas_token"; // Replace with your SAS token

    const blobServiceClient = new BlobServiceClient(
      `https://${account}.blob.core.windows.net?${sasToken}`
    );

    const containerName = "insurerportalfiles"; // Replace with your container name
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const directoryName = "59200 Test"; // Replace with your directory name

    let blobs = containerClient.listBlobsByHierarchy("/", { prefix: directoryName });
    let i = 1;
    for await (const item of blobs) {
      if (item.kind === "prefix") {
        console.log(`${i} - directory: ${item.name}`);
      } else {
        console.log(`${i} - file: ${item.name}`);
      }
      i++;
    }
  }
}

