import { NgModule } from '@angular/core';
import { RouterModule, Routes, InitialNavigation } from '@angular/router';
import { CaseDetailComponent } from '@app/_components/case-detail/case-detail.component';
import { CaseComponent } from '@app/_components/case/case.component';
import { HomeComponent } from '@app/_components/home';
import { AllOpenClaimsComponent } from '@app/_components/reports/all-open-claims/all-open-claims.component';
import { CatClaimsComponent } from '@app/_components/reports/cat-claims/cat-claims.component';
import { ClaimReceivedSummaryComponent } from '@app/_components/reports/claim-received-summary/claim-received-summary.component';
import { E2eEolComponent } from '@app/_components/reports/e2e-eol/e2e-eol.component';
import { ReportsComponent } from '@app/_components/reports/reports.component';
import { SharedFilesComponent } from './_components/shared-files/shared-files.component';
import { StatisticsComponent } from './_components/statistics/statistics.component';

//The app routing module defines the top level routes for the angular application
//and generates a root routing module by passing the array of routes to the RouterModule.forRoot() method. The module is imported into the main app module below.
//The home route maps the root path of the app to the home component,
//the users route maps to the users module and the account route maps to the account module, both feature module routes (/users and /account) are lazy loaded.
//The home and users routes are secured by passing the auth guard to the canActivate property of each route.


const routes: Routes = [
  {
    path: '', component: HomeComponent,
  },
  {
    path: 'cases',
    component: CaseComponent,
    data: { title: 'Case List' }
  },
  {
    path: 'statistics',
    component: StatisticsComponent,
    data: { title: 'Statistics Report' }
  },
  {
    path: 'case-details/:refNo',
    component: CaseDetailComponent,
    data: { title: 'Claim Details' }
  },
  {
    path: 'shared-files',
    component: SharedFilesComponent,
    data: { title: 'Shared Files' }
  },

  {
    path: 'reports',
    component: ReportsComponent,
    data: { title: 'Reports' }
  },
  {
    path: 'reports/cat-claims',
    component: CatClaimsComponent,
    data: { title: 'CAT Claims Report' }
  },
  {
    path: 'reports/e2e-eol',
    component: E2eEolComponent,
    data: { title: 'End To End Claims Report' }
  },
  {
    path: 'reports/claim-received-summary',
    component: ClaimReceivedSummaryComponent,
    data: { title: 'Claims Summary Report' }
  },
  {
    path: 'reports/all-open-claims',
    component: AllOpenClaimsComponent,
    data: { title: 'All Open Claims Report' }
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
